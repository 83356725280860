<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item label="车辆">
        <el-input v-model="queryForm.key" placeholder="名称/车牌号"></el-input>
      </el-form-item>
      <el-form-item label="时间范围" prop="dateRange">
        <el-date-picker v-model="dateRange" @change="handleRecordDateChange" :clearable="false" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch">查询</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
        <el-button @click="handleExport" icon="el-icon-download" type="primary">导出</el-button>
        <el-button @click="handlePrint" icon="el-icon-printer" type="primary">打印</el-button>
      </el-form-item>
    </el-form>

    <el-table id="service-table-id" :data="tableData" v-loading="tableLoading"
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" border stripe size="small" style="width: 100%;"
      max-height="550px">
      <el-table-column width="100" label="申请人" prop="applicant"></el-table-column>
      <el-table-column label="司机" prop="driver.name"></el-table-column>
      <el-table-column label="车辆" prop="car.name"></el-table-column>
      <el-table-column width="150" label="派车时间" prop="dispatch_time"></el-table-column>
      <el-table-column width="150" label="申请时间" prop="apply_time"></el-table-column>
      <el-table-column width="150" label="目的地" prop="target"></el-table-column>
      <el-table-column width="150" label="预计返回时间" prop="return_time"></el-table-column>
      <el-table-column width="150" label="实际离开时间" prop="real_out_time"></el-table-column>
      <el-table-column width="150" label="实际返回时间" prop="real_back_time"></el-table-column>
      <el-table-column label="状态" v-slot="{ row }">
        <el-tag :type=" row.status ? auditStatusDict[row.status].type : 'warning'"
          size="small">{{ row.status ? auditStatusDict[row.status].name : '已取消'}}</el-tag>
      </el-table-column>
      <el-table-column width="150" label="备注" prop="remark"></el-table-column>
      <el-table-column label="处理人" v-slot="{ row }">
        {{ row.user ? row.user.name : '' }}
      </el-table-column>
      <el-table-column label="审批人" v-slot="{ row }" width="200px">
        <el-image v-if="row.signature" style="width: 80px; height: 80px" :src="row.signature"
          :preview-src-list="[row.signature]">
        </el-image>
      </el-table-column>
      <el-table-column width="150" label="操作" fixed="right">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm v-if="row.status == 3" title="确定放行吗？" @confirm="handleLetPass(row)">
            <el-link slot="reference" type="primary" class="margin-r-10">放行</el-link>
          </el-popconfirm>
          <el-popconfirm v-if="row.status == 5" title="确定回营吗？" @confirm="handleReturn(row)">
            <el-link slot="reference" type="primary" class="margin-r-10">回营</el-link>
          </el-popconfirm>
          <el-popconfirm v-if="row.status < 5" title="确定取消吗？" @confirm="handleCancel(row.id)">
            <el-link slot="reference" type="primary" class="margin-r-10">取消</el-link>
          </el-popconfirm>
          <el-link v-if="row.member_id" @click="handleCall(row)" type="primary">呼叫</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="queryForm.page_size" @current-change="getVehicleServiceData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="550px" top="5vh">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="司机" prop="driver_id">
          <el-select v-model="form.driver_id" filterable clearable placeholder="请选择" class="el-input_inner--rewrite">
            <el-option v-for="item in driverData" :key="item.id" :label="item.name" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.name }}</span><span>{{ item.mobile }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆" prop="car_id">
          <el-select v-model="form.car_id" filterable clearable placeholder="请选择" class="el-input_inner--rewrite">
            <el-option v-for="item in vehicleData" :key="item.id" :label="item.name" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.name }}</span><span>{{ item.car_number }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="派车时间" prop="dispatch_time">
          <el-date-picker v-model="form.dispatch_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请人类型">
          <el-radio-group v-model="applicantType" @change="handleApplicantChange">
            <el-radio :label="1">老干部</el-radio>
            <el-radio :label="2">其他</el-radio>
          </el-radio-group>
          <div style="color: #FF0000;">注意：申请人类型为其他时，无法使用呼叫功能</div>
        </el-form-item>
        <el-form-item v-if="applicantType !== 1" label="申请人" prop="applicant">
          <el-input v-model="form.applicant"></el-input>
        </el-form-item>
        <el-form-item v-if="applicantType == 1" label="选择申请人" prop="member_id">
          <el-select v-model="form.member_id" clearable filterable remote :remote-method="getRecordData"
            :loading="recordLoading" placeholder="输入姓名进行查找" class="el-input_inner--rewrite">
            <el-option v-for="record in recordData" :key="record.id" :label="record.realname" :value="record.id">
              <div class="flex justify-content-s-b">
                <span>{{ record.realname }}</span><span>{{ record.birthday }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="applicantType !== 1" label="用车事由" prop="apply_reason">
          <el-input v-model="form.apply_reason"></el-input>
        </el-form-item>
        <el-form-item label="申请时间" prop="apply_time">
          <el-date-picker v-model="form.apply_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="目的地" prop="target">
          <el-input v-model="form.target"></el-input>
        </el-form-item>
        <el-form-item label="预计返回时间" prop="return_time">
          <el-date-picker v-model="form.return_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实际离开时间" prop="real_out_time">
          <el-date-picker v-model="form.real_out_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实际返回时间" prop="real_back_time">
          <el-date-picker v-model="form.real_back_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button @click="handleSubmit" :loading="submitting" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看派车" :visible.sync="showPreviewDialog" width="500px">
      <el-descriptions v-if="curRowData !== null" :column="1" border>
        <el-descriptions-item label="申请人">{{ curRowData.applicant }}</el-descriptions-item>
        <el-descriptions-item label="司机">{{ curRowData.driver ? curRowData.driver.name : '' }}</el-descriptions-item>
        <el-descriptions-item label="车辆">{{ curRowData.car ? curRowData.car.name : '' }}</el-descriptions-item>
        <el-descriptions-item label="派车时间">{{ curRowData.dispatch_time }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ curRowData.apply_time }}</el-descriptions-item>
        <el-descriptions-item label="目的地">{{ curRowData.target }}</el-descriptions-item>
        <el-descriptions-item label="预计返回时间">{{ curRowData.return_time }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag :type="curRowData.status ? auditStatusDict[curRowData.status].type : 'warning'"
            size="small">{{ curRowData.status ? auditStatusDict[curRowData.status].name : '已取消'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="实际离开时间">{{ curRowData.real_out_time }}</el-descriptions-item>
        <el-descriptions-item label="实际返回时间">{{ curRowData.real_back_time }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ curRowData.remark }}</el-descriptions-item>
        <el-descriptions-item label="处理人">{{ curRowData.user ? curRowData.user.name : '' }}</el-descriptions-item>
        <el-descriptions-item label="审批人">
          <el-image v-if="curRowData.signature" style="width: 80px; height: 80px" :src="curRowData.signature"
            :preview-src-list="[curRowData.signature]">
          </el-image>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>

    <VideoCallDialog ref="videoCall"></VideoCallDialog>
  </div>
</template>

<script>
  import VideoCallDialog from '@/components/VideoCallDialog.vue'

  import {
    formatDate
  } from '@/utils/common-fun.js'
  import {
    saveVehicleServiceAPI,
    getVehicleServiceDataAPI,
    getVehicleDataAPI,
    getDriverDataAPI,
    setVehicleValueAPI,
    cancelCarAPI
  } from '@/api/dispatch-vehicle.js'
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'

  export default {
    name: 'DispatchVehicleService',
    components: {
      VideoCallDialog,
    },
    data() {
      return {
        auditStatusDict: {
          0: {
            name: '已取消',
            type: 'info',
            value: 0
          },
          1: {
            name: '待安排',
            type: 'warning',
            value: 1
          },
          2: {
            name: '已安排',
            type: 'primary',
            value: 2
          },
          3: {
            name: '审批通过',
            type: 'success',
            value: 3
          },
          4: {
            name: '审批驳回',
            type: 'danger',
            value: 4
          },
          5: {
            name: '已出营',
            type: 'primary',
            value: 5
          },
          6: {
            name: '已返回',
            type: 'success',
            value: 6
          }
        },
        applicantType: 1, // 1=老人  2=其他

        // 档案数据
        recordData: [],
        recordLoading: false,

        queryForm: {
          page: 1,
          page_size: 10,
          key: '',
          start: '',
          end: '',
          // is_export: '', // 为 1 时导出，在导出按钮事件中添加
        },
        // 搜索时间区间
        dateRange: [],
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,

        vehicleData: [],
        vehicleTotal: 0,
        vehiclePageSize: 0,
        vehiclePage: 1,

        driverData: [],
        driverTotal: 0,
        driverPageSize: 0,
        driverPage: 1,

        // 查看
        showPreviewDialog: false,

        // 通话
        visibleCallDialog: false,
        videoCallKey: 0,

        // 表单相关
        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          car_id: '',
          applicant: '',
          member_id: '',
          apply_time: '',
          dispatch_time: '',
          return_time: '',
          target: '',
          driver_id: '',
          real_out_time: '',
          real_back_time: '',
          apply_reason: '', // 用车事由
        },
        rules: {
          car_id: [{
            required: true,
            message: '车辆不能为空',
            trigger: 'change'
          }],
          applicant: [{
            required: true,
            message: '申请人不能为空',
            trigger: 'change'
          }],
          apply_time: [{
            required: true,
            message: '申请时间不能为空',
            trigger: 'change'
          }],
          dispatch_time: [{
            required: true,
            message: '派车时间不能为空',
            trigger: 'change'
          }],
          return_time: [{
            required: true,
            message: '预计返回时间不能为空',
            trigger: 'change'
          }],
          driver_id: [{
            required: true,
            message: '司机不能为空',
            trigger: 'change'
          }],
          target: [{
            required: true,
            message: '目的地不能为空',
            trigger: 'blur'
          }],
          member_id: [{
            required: true,
            message: '申请人不能为空',
            trigger: 'change'
          }]
        }
      }
    },
    created() {
      // 设置默认时间，区间为30天，30天前-现在
      this.queryForm.end = formatDate(new Date()).split(' ')[0];
      this.queryForm.start = formatDate(new Date()).split(' ')[0];
      this.dateRange = [this.queryForm.start, this.queryForm.end]
      this.getVehicleData()
      this.getVehicleServiceData()
      this.getDriverData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
        this.form.applicantType = 1
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getVehicleServiceData()
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 1=老人 2=其他 
      handleApplicantChange(type) {
        if (type === 1) {
          // 申请人为老人时，将其他申请人和用车事由清空
          this.form.applicant = ''
          this.form.apply_reason = ''
        } else {
          this.form.member_id = ''
        }
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        this.curRowData.car_id = this.curRowData.car_id == 0 ? '' : this.curRowData.car_id;
        this.curRowData.driver_id = this.curRowData.driver_id == 0 ? '' : this.curRowData.driver_id;
        // 回显
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
        // 设置申请人类型
        if (this.curRowData.member_id) {
          this.applicantType = 1
          this.recordData = [{
            realname: this.curRowData.applicant,
            id: this.curRowData.member_id
          }]
        } else {
          this.form.member_id = ''
          this.applicantType = 2
        }
      },
      // 呼叫按钮
      handleCall(row) {
        this.$refs.videoCall.open(row.member_id)
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveVehicleServiceAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getVehicleServiceData()
          }).finally(() => this.submitting = false)
        })
      },
      // 获取车辆服务数据
      getVehicleServiceData() {
        this.tableLoading = true

        getVehicleServiceDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
      // 获取车辆数据
      getVehicleData() {
        getVehicleDataAPI({
          page: this.vehiclePage,
          page_size: 1000,
        }).then(res => {
          this.vehicleData = res.data
          this.vehiclePageSize = res.per_page
          this.vehicleTotal = res.total
        })
      },
      // 获取司机数据
      getDriverData() {
        getDriverDataAPI({
          page: this.driverPage,
          page_size: 1000,
        }).then(res => {
          this.driverData = res.data
          this.driverPageSize = res.per_page
          this.driverTotal = res.total
        })
      },
      // 获取档案数据
      getRecordData(keyword) {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      },
      // 记录时间选择 --顶部搜索框
      handleRecordDateChange(e) {
        if (e == null) {
          this.queryForm.start = ''
          this.queryForm.end = ''
        } else {
          this.queryForm.start = e[0]
          this.queryForm.end = e[1]
        }
      },
      // 导出
      handleExport() {
        let form = JSON.parse(JSON.stringify(this.queryForm));
        form.is_export = 1;
        getVehicleServiceDataAPI(form).then(res => {
          // 未直接下载文件，采用此方法下载
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = res
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
      },
      // 打印按钮
      handlePrint() {
        if (this.dateRange == null) {
          return this.$message.info('请选择时间范围后再点击打印')
        }
        let host = window.location.origin
        let route = '/print/dispatch-car-service'
        let params = `${this.queryForm.start}/${this.queryForm.end}`
        let url = `${host}${route}?dateRange=${params}`
        window.open(url, '_blank')
      },
      // 放行，设置放行时间
      handleLetPass(row) {
        let form = {
          id: row.id,
          field: 'real_out_time',
          value: formatDate(new Date())
        }
        this.comfirmEdit(form);
      },
      // 返回营地
      handleReturn(row) {
        let form = {
          id: row.id,
          field: 'real_back_time',
          value: formatDate(new Date())
        }
        this.comfirmEdit(form);
      },
      // 直接提交编辑
      comfirmEdit(data) {
        // 提交
        setVehicleValueAPI(data).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getVehicleServiceData()
          }
        })
      },
      // 取消派车
      handleCancel(id) {
        cancelCarAPI({
          id
        }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getVehicleServiceData()
          }
        })
      }
    }
  }
</script>

<style>
</style>